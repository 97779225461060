.rm-popup {
  
  position: absolute;
  display: none;
  height: 175px;
  width: 460px;
  z-index: 999;
  top: -165px;
  left: 50%;
  margin-left: -210px;
  cursor: default;

  .rm-popup-content {
    overflow: hidden;
    height: 100%;
  }

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: -13px;
    left: 50%;
    margin-left: -10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }

  &.active {
    display: block;
    opacity: 0;

    &.fade-in {
      opacity: 1;

      transition: opacity .2s ease-in-out;
      -o-transition: opacity .2s ease-in-out;
      -moz-transition: opacity .2s ease-in-out;
      -webkit-transition: opacity .1s ease-in-out;
    }

    transition: opacity .2s ease-in-out;
    -o-transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
    -webkit-transition: opacity .2s ease-in-out;
  }

}