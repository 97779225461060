$scrollbar-width: 11px;
$scrollbar-color: #f0f0f0;
$scroll-thumb-color: #5a7687;
$scroll-border-radius: 11px;

.scroll-viewport {
  overflow: hidden;
  position: relative;
}
.scroll-overview {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  margin: 0;
}
.scroll-bar{
  //background-color: $scrollbar-color;
  background: #d8d8d8;
  background: -moz-linear-gradient(left, #d8d8d8 0%, #f0f0f0 50%, #d8d8d8 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, #d8d8d8), color-stop(50%, #f0f0f0), color-stop(100%, #d8d8d8));
  background: -webkit-linear-gradient(left, #d8d8d8 0%, #f0f0f0 50%, #d8d8d8 100%);
  background: -o-linear-gradient(left, #d8d8d8 0%, #f0f0f0 50%, #d8d8d8 100%);
  background: -ms-linear-gradient(left, #d8d8d8 0%, #f0f0f0 50%, #d8d8d8 100%);
  background: linear-gradient(to right, #d8d8d8 0%, #f0f0f0 50%, #d8d8d8 100%);
  border-radius: $scroll-border-radius;
  position: absolute;
  top: 0;
  right: 0;
  width: $scrollbar-width;
  transition: 0.6s ease-in-out opacity;
  height: 100%;

  &.disable { display: none; }

  &.visible {
    opacity: 1 !important;
  }
  &:hover {
    display: block;
  }
}
.scroll-thumb {
  background-color: $scroll-thumb-color;
  border-radius: $scroll-border-radius;
  height: 20px;
  width: $scrollbar-width - 2px;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 1px;
}

body.scroll-no-select {
  user-select: none;
}
