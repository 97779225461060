@-webkit-keyframes fadeInLeftSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeftSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInLeftSmall {
  -webkit-animation-name: fadeInLeftSmall;
  animation-name: fadeInLeftSmall;
}

@-webkit-keyframes fadeInRightSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRightSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(10%, 0, 0);
    transform: translate3d(10%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRightSmall {
  -webkit-animation-name: fadeInRightSmall;
  animation-name: fadeInRightSmall;
}

@-webkit-keyframes fadeInDownSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDownSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInDownSmall {
  -webkit-animation-name: fadeInDownSmall;
  animation-name: fadeInDownSmall;
}

@-webkit-keyframes fadeInUpSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUpSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0);
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUpSmall {
  -webkit-animation-name: fadeInUpSmall;
  animation-name: fadeInUpSmall;
}

@-webkit-keyframes bounceInDownSmall {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes bounceInDownSmall {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
    -webkit-transofrm: rotate(360deg);
  }
}

.bounceInDownSmall {
  -webkit-animation-name: bounceInDownSmall;
  animation-name: bounceInDownSmall;
}

// Animations
.fade{
    &:hover{
        background-color: transparent;
        color: inherit;
    }
}
.fade-in{
    opacity: 0;
    @extend .animated;
    &.in{
        @extend .fadeIn;
    }
}
.fade-in-up{
    opacity: 0;
    @extend .animated;
    &.in{
        @extend .fadeInUp;
    }
}
.fade-in-down{
    opacity: 0;
    @extend .animated;
    &.in{
        @extend .fadeInDown;
    }
}
.fade-in-left{
    opacity: 0;
    @extend .animated;
    &.in{
        @extend .fadeInLeft;
    }
}
.fade-in-right{
    opacity: 0;
    @extend .animated;
    &.in{
        @extend .fadeInRight;
    }
}
.fade-in-up-small{
    opacity: 0;
    @extend .animated;
    &.in{
        @extend .fadeInUpSmall;
    }
}
.fade-in-down-small{
    opacity: 0;
    @extend .animated;
    &.in{
        @extend .fadeInDownSmall;
    }
}
.fade-in-left-small{
    opacity: 0;
    @extend .animated;
    &.in{
        @extend .fadeInLeftSmall;
    }
}
.fade-in-right-small{
    opacity: 0;
    @extend .animated;
    &.in{
        @extend .fadeInRightSmall;
    }
}
.zoom-in{
    opacity: 0;
    @extend .animated;
    &.in{
        @extend .zoomIn;
        opacity: 1;
    }
}
.bounce-in{
    opacity: 0;
    @extend .animated;
    &.in{
        @extend .bounceIn;
    }
}
.bounce-in-down{
    opacity: 0;
    @extend .animated;
    &.in{
        @extend .bounceInDown;
    }
}
.bounce-in-down-small{
    opacity: 0;
    @extend .animated;
    &.in{
        @extend .bounceInDownSmall;
        opacity: 1;
    }
}
.rotate-in-down-left{
    opacity: 0;
    @extend .animated;
    &.in{
        @extend .rotateInDownLeft;
    }
}
.rotate-in-down-right{
    opacity: 0;
    @extend .animated;
    &.in{
        @extend .rotateInDownRight;
    }
}

.rotate-indefinitely {
  @extend .animated;
  animation: spin 1.2s linear infinite;
}