@charset "UTF-8";

%readability{
  -webkit-font-smoothing: subpixel-antialiased !important;
  -webkit-backface-visibility: hidden !important;
  -webkit-transform: translateZ(0) scale(1.0, 1.0) !important;
  text-rendering: optimizeLegibility !important;
  //text-shadow: 1px 1px 1px rgba(0,0,0,0.004) !important;
}

@font-face {
  font-family: 'Garamond';
  src: url('../../fonts/EBGaramond08-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}