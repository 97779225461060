// Scrollmagic triggers
.microsite, .planned-community{
	#trigger-1{
		position: absolute;
		top: -145px;
	}
	#trigger-2{
	    position: absolute;
	    top: -100px;
	}
	#trigger-3{
		position: absolute;
	    top: -100px;
	}
}