$white: #ffffff;
$white-smoke: #f5f5f5;
$gray: #808080;
$gray-alto: #dbdbdb;
$gray-celeste: #cfcfcf;
$gray-gainsboro: #dcdcdc;
$gray-heather: #B3C6D1;
$gray-dark: #545454;
$dark-blue: rgba(2, 44, 71, 0.95);
$light-blue: #3d86b0;
$sky-blue: rgba(61, 134, 176, 0.95);
$hover-blue: #3177a0;
$blue: #185c83;
$blue-dk: #185c83;
$blue-lt: #a3cfe9;
$blue-steel: #4487ac;
$blue-horizon: #5e849f;
$blue-jelly-bean: #2574a2;
$blue-calypso: #2F6C8F;
$blue-biscay: #1a4a65;
$blue-danube: #5397BE;
$orange: #cd6017;
$orange-light: #e46c2a; 
$orange-boder: #df8233;
$black: #545454;


// Font base
$base-font: 16;

// Default hero height
$height: 650px;

//== Media queries breakpoints
$desktop-xxl: "only screen and (min-height: 951px)";
$desktop-xl: "only screen and (min-width: 1792px)";
$desktop-hd: "only screen and (min-width: 1200px)";
$desktop: "only screen and (min-width: 992px) and (max-width: 1199px)";
$laptop: "only screen and (max-height: 950px)";
$tablet: "only screen and (min-width: 768px) and (max-width: 991px)";
$phone: "only screen and (max-width: 767px)";
$phone-sm: "only screen and (max-width: 320px)";
$phone-landscape: "only screen and (max-width: 767px) and (orientation: landscape)";
$tablet-landscape: "only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation : landscape)";
//@ipad-portrait: "only screen and (min-device-width: 768px) and (max-device-width: 991px) and (orientation : Portrait)";
$safari: "(overflow:-webkit-marquee) and (justify-content:inherit)";
