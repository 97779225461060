////
/// Open-Source Collection of Useful SASS Mixins Library.
/// By: Gilles Bertaux | http://gillesbertaux.com | @gillesbertaux
/// The purpose of Andy is to gather useful mixins and avoid endless research
/// or heavy framework use. Feel free to fork it on Github and add your own mixins:
////


/// Base font size setting for Andy
/// @access public
/// @type Length
$base-font-size: 16px !default;


/// Mixin helper to output vendor-prefixed CSS
/// @access private
/// @author HugoGiraudel
/// @param {String} $property - Unprefixed CSS property
/// @param {*} $value - Raw CSS value
/// @param {List} $prefixes [()] - List of prefixes to output
@mixin prefix($property, $value, $prefixes: ()) {
  @each $prefix in $prefixes {
    -#{$prefix}-#{$property}: $value;
  }

  #{$property}: $value;
}


/// Mixin helper to prefix `@keyframes`
/// @access private
/// @param {String} $name - Animation name
@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }

    @keyframes #{$name} {
        @content;
    }
}


/// Function helper to strip a single character from a string; by default, simply removes the character.
/// @access private
/// @param {String} $string - The string to alter.
/// @param {String} $to-replace - The character to check for.
/// @param {String} $replacement [''] - The character to replace the removed character with.
/// @example scss - Usage {
///   .foo:after {
///     content: str-replace('Hello.', '.', '!');
///   }
/// @example css - Result
///   .foo:after {
///     content: 'Hello!'
///   }
@function str-replace($string, $to-replace, $replacement:'') {
  $target-location: str-index($string, $to-replace);
  @if $target-location == null { @return $string; }
  $string-without-target: str-slice($string, 1, $target-location - 1) + str-slice($string, $target-location + 1);
  $string: str-insert($string-without-target, $replacement, $target-location);
  @return $string;
}


/// Background gradient helper
/// @access public
/// @param {Color} $start-color - Start color
/// @param {Color} $end-color - End color
/// @param {String} $orientation - Type of gradient, either `vertical`, `horizontal` or `radial`
/// @example scss - Usage
///   .foo {
///     @include background-gradient(red, black, 'vertical');
///   }
/// @example css - Result
///   .foo {
///     background: -webkit-linear-gradient(top, red, black);
///     background: linear-gradient(to bottom, red, black);
///   }
@mixin background-gradient($start-color, $end-color, $orientation) {
    background: $start-color;

    @if $orientation == 'vertical' {
      background: -webkit-linear-gradient(top, $start-color, $end-color);
      background: linear-gradient(to bottom, $start-color, $end-color);
    } @else if $orientation == 'horizontal' {
      background: -webkit-linear-gradient(left, $start-color, $end-color);
      background: linear-gradient(to right, $start-color, $end-color);
    } @else {
      background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
      background: radial-gradient(ellipse at center, $start-color, $end-color);
    }
}


/// Background size helper
/// @access public
/// @param {Length} $width - Background width
/// @param {Length} $height [$width] - Background height
/// @example scss - Usage
///   .foo {
///     @include background-size(100%);
///   }
/// @example scss - Result
///   .foo {
///     -webkit-background-size: 100% 100%;
///     -moz-background-size: 100% 100%;
///     background-size: 100% 100%;
///   }
@mixin background-size($width, $height: $width) {
    @include prefix(background-size, $width $height, 'webkit' 'moz');
}


/// Separated border-radius helpers
/// @access public
/// @param {Length} $top-left-radius - Top left radius
/// @param {Length} $top-right-radius - Top right radius
/// @param {Length} $bottom-right-radius - Bottom right radius
/// @param {Length} $bottom-left-radius - Bottom left radius
/// @example scss - Usage
///   .foo {
///     @include border-radius-separate(1px, 2px, 3px, 4px);
///   }
/// @example css - Result
///   .foo {
///     -webkit-border-top-left-radius: 1px;
///     -moz-border-top-left-radius: 1px;
///     border-top-left-radius: 1px;
///     -webkit-border-top-right-radius: 2px;
///     -moz-border-top-right-radius: 2px;
///     border-top-right-radius: 2px;
///     -webkit-border-bottom-right-radius: 3px;
///     -moz-border-bottom-right-radius: 3px;
///     border-bottom-right-radius: 3px;
///     -webkit-border-bottom-left-radius: 4px;
///     -moz-border-bottom-left-radius: 4px;
///     border-bottom-left-radius: 4px;
///   }
@mixin border-radius-separate($top-left-radius, $top-right-radius, $bottom-right-radius, $bottom-left-radius) {
    @include prefix(border-top-left-radius, $top-left-radius, 'webkit' 'moz');
    @include prefix(border-top-right-radius, $top-right-radius, 'webkit' 'moz');
    @include prefix(border-bottom-right-radius, $bottom-right-radius, 'webkit' 'moz');
    @include prefix(border-bottom-left-radius, $bottom-left-radius, 'webkit' 'moz');
}


/// Box-sizing helper
/// @access public
/// @param {String} $type - Either `border-box`, `padding-box` or `content-box`
/// @example scss - Usage
///   .foo {
///     @include box-sizing(border-box);
///   }
/// @example css - Result
///   .foo {
///     -moz-box-sizing: border-box;
///     box-sizing: border-box;
///   }
@mixin box-sizing($type) {
    @include prefix(box-sizing, $type, 'moz');
}


/// Horizontally centers block elements
/// @access public
/// @example scss - Usage
///   .foo {
///     @include center-block;
///   }
/// @example css - Result
///   .foo {
///     display: block;
///     margin-left: auto;
///     margin-right: auto;
///   }
@mixin center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}


/// Horizontally and vertically centers block elements
/// Important: you must have a parent element with `position: relative`.
/// @access public
/// @example scss - Usage
///   .foo {
///     @include center-both;
///   }
/// @example css - Result
///   .foo {
///     position: absolute;
///     top: 50%;
///     left: 50%;
///     -webkit-transform: translate(-50%, -50%);
///     -ms-transform: translate(-50%, -50%);
///     transform: translate(-50%, -50%);
///   }
@mixin center-both {
    position: absolute;
    top: 50%;
    left: 50%;
    @include prefix(transform, translate(-50%, -50%), 'webkit' 'ms');
}


/// Vertically centers block elements with known height.
/// @access public
/// @param {Length} $height - Element's height
/// @example scss - Usage
///   .foo {
///     @include center-h(42px);
///   }
/// @example css - Result
///   .foo {
///     position: absolute;
///     top: 50%;
///     height: 42px;
///     margin-top: -21px;
///   }
@mixin center-h($height) {
    position: absolute;
    top: 50%;
    height: $height;
    margin-top: -($height / 2);
}

/// Vertically centers block elements with unknown height.
/// @access public
/// @example scss - Usage
///   .foo {
///     @include center-h--unk;
///   }
/// @example css - Result
///   .foo {
///     position: relative;
///     top: 50%;
///     -webkit-transform: translateY(-50%);
///     -ms-transform: translateY(-50%);
///     transform: translateY(-50%);
///   }
@mixin center-h--unk {
    position: absolute;
    top: 50%;
    @include prefix(transform, translateY(-50%), 'webkit' 'ms');
}


/// Clearfix extend
/// @access public
/// @example scss - Usage
///   .foo {
///     @extend %clearfix;
///   }
%clearfix {
    *zoom: 1;

    &:before, &:after {
        content: ' ';
        display: table;
    }

    &:after {
        clear: both;
    }
}


/// Adds a thin black line above the element, and a thin gray line below the element, creating an inlay effect.
/// @access public
/// @example scss - Usage
///   .foo {
///     @include outline;
///   }
/// @example css - Result
///   .foo {
///     box-shadow: rgba(255, 255, 255, 0.5) 0 1px 0, inset rgba(0, 0, 0, 0.75) 0 1px 0;
///   }
@mixin outline {
    box-shadow: rgba(255, 255, 255, 0.5) 0 1px 0, inset rgba(0, 0, 0, 0.75) 0 1px 0;
}


/// Given the location of a webfont, will generate a font-face declaration with multiple file formats.
/// @access public
/// @param {String} $font-name - Font family name
/// @param {String} $file-name - File name (no extension)
/// @param {String | Number} $weight [normal] - Font weight
/// @param {String} $style [normal] - Font style
/// @example scss - Usage
///   @include font-face('gotham', '/fonts/gotham');
@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    src: url($file-name + '.eot');
    src: url($file-name + '.eot?#iefix')  format('embedded-opentype'),
         url($file-name + '.woff') format('woff'),
         url($file-name + '.ttf')  format('truetype'),
         url($file-name + '.svg##{$font-name}')  format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}


/// Given a font size in pixels, reproduces that font size in rems.
/// @access public
/// @param {Length} $size - Font size
/// @example scss - Usage
///   .foo {
///     @include font-size(16px);
///   }
/// @example css - Result
///   .foo {
///     font-size: 16px;
///     font-size: 1rem;
///   }
@mixin font-size($size) {
  @if unitless($size) {
    $size: $size * 1px;
  }

  font-size: $size;
  font-size: ($size / $base-font-size) * 1rem;
}


/// Forces browsers to use hardware acceleration for transforms
/// @access public
/// @example scss - Usage
///   .foo {
///     @include ha;
///   }
/// @example css - Result
///   .foo {
///     -webkit-transform: translate3d(0, 0, 0);
///     -moz-transform: translate3d(0, 0, 0);
///     transform: translate3d(0, 0, 0);
///   }
@mixin ha {
    @include prefix(transform, translate3d(0, 0, 0), 'webkit' 'ms');
}


/// Retina image media query helper; given an image path with a 2x-sized version of an image, will load that image as a background-image on high-resolution devices.
/// @access public
/// @param {String} $image - Image path
/// @param {Length} $width - Image width
/// @param {Height} $height - Image height
/// @example scss - Usage
///   .foo {
///     @include image-2x('../images/image.png', 100%, auto);
///   }
@mixin image-2x($image, $width, $height) {
    @media (-webkit-min-device-pixel-ratio: 1.3),
           (min-resolution: 124dpi),
           (min-resolution: 1.3dppx) {
        /* on retina, use image that's scaled by 2 */
        background-image: url($image);
        background-size: $width $height;
    }
}


/// Generates line-height values in both pixels and rems.
/// @access public
/// @param {Number} $height-value [12] - Height value
/// @example scss - Usage
///   .foo {
///     @include line-height(16);
///   }
/// @example css - Result
///   .foo {
///     line-height: 16px;
///     line-height: 1rem;
///   }
@mixin line-height($height-value: 12) {
    line-height: $height-value * 1px; //fallback for old browsers
    line-height: (1 / ($base-font-size / ($base-font-size * 0 + 1)) * $height-value * 1rem);
}


/// Media query helper for declaring media queries by width, and, optionally, ratio.
/// @access public
/// @param {Length} $width - Max-width
/// @param {Number | False} $ratio [false] - Min device pixel ratio
/// @example scss - Usage
///   .foo {
///     @include mquery(350px, 2) {
///       width: 100%;
///     }
///   }
@mixin mquery($width, $ratio: false) {
    @if $ratio {
        @media
            only screen and (max-width: $width) and  (min--moz-device-pixel-ratio: $ratio),
            only screen and (max-width: $width) and  (-webkit-min-device-pixel-ratio: $ratio),
            only screen and (max-width: $width) and  (min-device-pixel-ratio: $ratio) {
            @content;
        }
    } @else {
        @media only screen and (max-width: $width) {
            @content;
        }
    }
}


/// Media query helper for declaring media queries by device pixel ratio.
/// @access public
/// @param {Number} $ratio - Min device pixel ratio
/// @example scss - Usage
///   .foo {
///     @include mquery-r(2) {
///       width: 100%;
///     }
///   }
@mixin mquery-r($ratio) {
    @media
        only screen and (-webkit-min-device-pixel-ratio: $ratio),
        only screen and (min--moz-device-pixel-ratio: $ratio),
        only screen and (-o-min-device-pixel-ratio: $ratio),
        only screen and (min-device-pixel-ratio: $ratio) {
        @content;
    }
}


/// Given an opacity value, generates that value as well as a way to display that opacity value in Internet Explorer 8 and 9.
/// @access public
/// @param {Float} $opacity - Opacity
/// @example scss - Usage
///   .foo {
///     @include opacity(0.5);
///   }
/// @example css - Result
///   .foo {
///     opacity: 0.5;
///     filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#50)";
///   }
@mixin opacity($opacity) {
    opacity: $opacity;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$opacity * 100})";
}


/// Generates the color black, with, optionally, a set opacity.
/// @access public
/// @param {Float} $opacity - Opacity
/// @example scss - Usage
///   .foo {
///     border-color: black(0.1);
///   }
/// @example css - Result
///   .foo {
///     border-color: rgba(0, 0, 0, 0.1);
///   }
@function black($opacity) {
    @return rgba(0, 0, 0, $opacity);
}


/// Generates the color white, with, optionally, a set opacity.
/// @access public
/// @param {Float} $opacity - Opacity
/// @example scss - Usage
///   .foo {
///     border-color: white(0.1);
///   }
/// @example css - Result
///   .foo {
///     border-color: rgba(255, 255, 255, 0.1);
///   }
@function white($opacity) {
    @return rgba(255, 255, 255, $opacity);
}


/// Shorthandizes position declarations.
/// @access public
/// @param {String} $type - Either `relative`, `absolute` or `fixed`
/// @param {Length} $left [null] - Left offset
/// @param {Length} $right [null] - Right offset
/// @param {Length} $top [null] - Top offset
/// @param {Length} $bottom [null] - Bottom offset
/// @example scss - Usage
///   .foo {
///     @include position(absolute, $top: 10px, $left: 10px);
///   }
/// @example css - Result
///   .foo {
///     position: absolute;
///     left: 10px;
///     top: 10px;
///   }
@mixin position($type: absolute, $top: 0, $right: 0, $bottom: 0, $left: 0) {
    position: $type;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}


/// Sizing helper
/// @access public
/// @param {Length} $width - Width
/// @param {Length} $height [$width] - Height
/// @example scss - Usage
///   .foo {
///     @include size(350px);
///   }
/// @example css - Result
///   .foo {
///     width: 350px;
///     height: 350px;
///   }
@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}


/// Embossing text shadow
/// @access public
/// @param {Float} $value - Opacity value
/// @example scss - Usage
///   .foo {
///     @include text-shadow(0.5);
///   }
/// @example css - Result
///   .foo {
///     text-shadow: rgba(255, 255, 255, 0.5) 0 1px 0;
///   }
@mixin text-shadow($value) {
    text-shadow: rgba(255, 255, 255, $value) 0 1px 0;
}


/// Automatically prefix any transform
/// @access public
/// @param {String} $transformation - The transform to apply
/// @example scss - Usage
///   .foo {
///     @include transform(translateX(10px));
///   }
/// @example css - Result
///   .foo {
///     -webkit-transform: translateX(10px);
///     -ms-transform: translateX(10px);
///     transform: translateX(10px);
///   }
@mixin transform($transformation){
  @include prefix(transform, $transformation, 'webkit' 'ms');
}


/// Transition helper
/// @param {Time} $time [1s] - Duration
/// @param {String} $timing-function [ease-in-out] - Timing function or cubic bezier curve
/// @example scss - Usage
///   .foo {
///     @include transition(0.3s, ease-in);
///   }
/// @example css - Result
///   .foo {
///     -webkit-transition: all 0.3s ease-in;
///     transition: all 0.3s ease-in;
///   }
@mixin transition($time: 1s, $timing-function: ease-in-out) {
    @include prefix(transition, all $time $timing-function, 'webkit');
}

/// Specific transition helper
/// @param {Time} $time [1s] - Duration
/// @param {String} $timing-function [ease-in-out] - Timing function or cubic bezier curve
/// @example scss - Usage
///   .foo {
///     @include specific-transition(opacity, 0.3s, ease-in);
///   }
/// @example css - Result
///   .foo {
///     -webkit-transition: all 0.3s ease-in;
///     transition: all 0.3s ease-in;
///   }
@mixin specific-transition($property, $time: 1s, $timing-function: ease-in-out) {
    @include prefix(transition, $property $time $timing-function, 'webkit');
}

/// Generates a grow-then-shrink (or shrink-then-grow) animation using transform(scale).
/// @access public
/// @param {Number} $scale-change [1.1] - The amount to scale by.
/// @param {List} $animation-properties - Animation properties to apply.
/// @example scss - Usage
///   .foo {
///     @include scale(0.5, 3s ease infinite alternate);
///   }
/// @example css - Result
///   .foo {
///     -webkit-animation: "scale-0-5" 3s ease infinite alternate;
///     animation: "scale-0-5" 3s ease infinite alternate;
///   }
///  // -webkit- prefixed @keyframes are also generated
///  @keyframes scale-0-5 {
///    from, to {
///      -webkit-transform: scale(1);
///      -ms-transform: scale(1);
///      transform: scale(1);
///    }
///    50% {
///      -webkit-transform: scale(0.5);
///      -ms-transform: scale(0.5);
///      transform: scale(0.5);
///    }
///  }
@mixin scale($scale-change:1.1, $animation-properties: 1s ease-in-out) {
  $alias: 'scale-' + str-replace($scale-change + '', '.', '-');

  @include keyframes($alias){
    0%, 100% {
      @include transform(scale(1));
    }
    50% {
      @include transform(scale($scale-change));
    }
  }

  @include prefix(animation, $alias $animation-properties, 'webkit');
}


/// Given two opacity values, animates an element between those opacity values.
/// @access public
/// @param {Number} $fade-from [0] - The beginning opacity value.
/// @param {Number} $fade-to [1] - The final opacity value.
/// @param {List} $animation-properties [1s ease] - The animation properties to apply.
/// @example scss - Usage
///   .foo {
///     @include fade(.8, .2, 3s linear);
///   }
/// @example css - Result
///   .foo {
///     -webkit-animation: fade-0-8-0-2 3s linear;
///     animation: fade-0-8-0-2 3s linear;
///   }
/// // (the @keyframes are also generated with a -webkit- vendor prefix)
///   @keyframes fade-0-8-0-2 {
///     from {
///       opacity: 0.8;
///       -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
///     }
///     to {
///       opacity: 0.2;
///       -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
///     }
///   }
@mixin fade($fade-from: 0, $fade-to: 1, $animation-properties: 1s ease) {
    $alias: fade- + str-replace($fade-from + '', '.', '-') + '-' + str-replace($fade-to + '', '.', '-');
    @include keyframes($alias){
    from {
      @include opacity($fade-from);
    }
    to {
      @include opacity($fade-to);
    }
  }
  @include prefix(animation, $alias $animation-properties, 'webkit');
}


/// Slide-in-from creates and calls an animation that slides an element on a given axis for a given amount of space. The offset is measured in the distance from the location the element would naturally fall in, were transforms not applied to it.
/// @access public
/// @param {String} $slide-axis [x] - The axis on which to slide; 'x' or 'y'.
/// @param {Length} $slide-offset [-100px] - The offset from the natural element position from which to begin the animation.
/// @param {List} $animation-properties [3s ease-out] - Any animation properties to be included.
/// @example scss - Usage
///   .foo {
///     @include slide-in-from(y, -200px, 3s ease alternate infinite);
///   }
/// @example css - Result
///   .foo {
///     -webkit-animation: slide-in-y--200px 3s linear infinite alternate;
///     animation: slide-in-y--200px 3s linear infinite alternate;
///   }
///
/// // (the @keyframes are also generated with a -webkit- vendor prefix)
///   @keyframes slide-in-y--200px {
///     0% {
///       opacity: 0;
///       -webkit-transform: translateY(-200px);
///       -ms-transform: translateY(-200px);
///       transform: translateY(-200px);
///     }
///     75% {
///       -webkit-transform: translateY(0);
///       -ms-transform: translateY(0);
///       transform: translateY(0);
///     }
///     100% {
///       opacity: 1;
///     }
///   }
@mixin slide-in-from($slide-axis: x, $slide-offset: -100px, $animation-properties: 3s ease-out) {
  $slide-start: if($slide-axis == x, translateX($slide-offset), translateY($slide-offset));
  $slide-end: if($slide-axis == x, translateX(0), translateY(0));

  @include keyframes(slide-in-#{$slide-axis}-#{$slide-offset}){
    0% {
      @include opacity(0);
      @include transform($slide-start);
    }
    75% {
      @include transform($slide-end);
    }
    100% {
      @include opacity(1);
    }
  }

  @include prefix(animation, slide-in-#{$slide-axis}-#{$slide-offset} $animation-properties, 'webkit');
}

/// Transition delay
@mixin transition-delay($delay...) {
    -moz-transition-delay:    $delay;
    -o-transition-delay:      $delay;
    -webkit-transition-delay: $delay;
    transition-delay:         $delay;
}

/**
 * Animation delay
 */

@mixin animation-delay($delay){
  -webkit-animation-delay: $delay; /* Safari 4.0 - 8.0 */
    animation-delay: $delay;
}

@mixin animation($value) {
  -webkit-animation: $value;
  -moz-animation: $value;
  -ms-animation: $value;
  -o-animation: $value;
  animation: $value;
}

/**
 * Background Image
 * @url  {[type]}
 * @attachment  {[type]}
 * @size  {[type]}
 * @pos-y  {[type]}
 * @pos-x  {[type]}
 */
@mixin background-image($attachment: scroll, $size: cover, $pos: center top){
    background-repeat: no-repeat;
    background-position: $pos;
    background-size: $size;
    -webkit-background-size: $size;
    -moz-background-size: $size;
    background-attachment: $attachment;
    @media #{$tablet-landscape}{
      background-attachment: scroll;
    }
    @media #{$tablet}{
      background-attachment: scroll;
    }
    @media #{$phone}{
      background-attachment: scroll;
    }
    @content;
}
/**
 * Position a background image in a pseudo element
 * @param  {[type]} $side:       left          [description]
 * @param  {[type]} $size:       30%           [description]
 * @param  {[type]} $attachment: scroll        [description]
 * @param  {[type]} $size:       cover         [description]
 * @return {[type]}              [description]
 */
@mixin background-pseudo($side: left, $width: 30%, $pseudo: after, $attachment: scroll, $size: cover){
    
    &:#{$pseudo}{
      @include position(absolute, 0, 0, 0, auto);
      
      @if $side == 'left' {
        @include position(absolute, 0, auto, 0, 0);
      }

      content: '';
      @include size($width, 100%);
      @include background-image($attachment, $size, $side top);
      z-index: 0;
      @media #{$phone}{
          @include size(100%, 250px); 
      }
      @content;
    }
}

//flex

@mixin flex-param($dir: row, $justify: center, $align: center, $type: flex){
  display: $type;
  flex-direction: $dir;
  justify-content: $justify;
  align-items: $align;
  @content;
}

@mixin transform-origin($val) {
  -webkit-transform-origin: $val;
  -moz-transform-origin: $val;
  -ms-transform-origin: $val;
  transform-origin: $val;
}

@mixin list-columns($val){
  -webkit-columns: $val;
  -moz-columns: $val;
  columns: $val;
}

@mixin rotateX($deg, $position:center){
  transform-origin: $position;
  -webkit-transform: rotateX($deg+deg);
  -moz-transform: rotateX($deg+deg);
  transform: rotateX($deg+deg);
}

@mixin rotateY($deg, $position:center){
  transform-origin: $position;
  -webkit-transform: rotateY($deg+deg);
  -moz-transform: rotateY($deg+deg);
  transform: rotateY($deg+deg);
}

/**
 * SVG
 */
@mixin svgcolor($color, $stroke: 'no-stroke'){
    path, polygon, rect, ellipse, circle, line{
      &:not(.no-fill){
        fill: $color;
      }
      @if($stroke != 'no-stroke'){
        stroke: $color;
      }
    }
}

/**
 * Detect ie explorer
 */
@mixin ie(){
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
       /* IE10+ CSS styles go here */
       @content;
  }
  @supports (-ms-accelerator:true) {
    /* IE Edge 12+ CSS styles go here */
    @content; 
  }
}

/**
 * List animation
 */
@mixin animation-stagger($animation-name: '.fadeInLeft', $selector: 'li', $amount: 10, $animation-delay: '0.25s'){
  #{$selector}{
    opacity: 0;
  }
  &.in{
      #{$selector}{
          @extend .animated;
          @extend #{$animation-name};
      }
      @for $i from 1 to $amount {
          #{$selector}:nth-child(#{$i}) {
              animation-delay: $i * $animation-delay;
              @media #{$phone}{
                  animation-delay: 0.2s;
              }
          }
      }
  }
}

/**
 * Misc
 */
@mixin color-hover($color1, $color2){
  color: $color1;
  @include transition(.3s, ease-in-out);
  &:hover{
    color: $color2;
  }
  @content;
}

@mixin orange-shadow($content: 2px 2px 0px 1px #b13f0c){
	-webkit-box-shadow: $content;
	-moz-box-shadow: $content;
	box-shadow: $content;
}

@mixin box-shadow($content: 2px 2px 0px 1px #b13f0c){
  -webkit-box-shadow: $content;
  -moz-box-shadow: $content;
  box-shadow: $content;
}

@mixin border-radius($radius: 5px) {
	-webkit-border-radius: $radius;
	-moz-border-radius:    $radius;
	border-radius:         $radius;

	-moz-background-clip:    padding;
	-webkit-background-clip: padding-box;
	background-clip:         padding-box;
}
@mixin border-radiuses($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
	-webkit-border-top-right-radius:    $topright;
	-webkit-border-bottom-right-radius: $bottomright;
	-webkit-border-bottom-left-radius:  $bottomleft;
	-webkit-border-top-left-radius:     $topleft;

	-moz-border-radius-topright:        $topright;
	-moz-border-radius-bottomright:     $bottomright;
	-moz-border-radius-bottomleft:      $bottomleft;
	-moz-border-radius-topleft:         $topleft;

	border-top-right-radius:            $topright;
	border-bottom-right-radius:         $bottomright;
	border-bottom-left-radius:          $bottomleft;
	border-top-left-radius:             $topleft;

	-moz-background-clip:    padding;
	-webkit-background-clip: padding-box;
	background-clip:         padding-box;
}

@mixin mixin-transition($sec,$transition){
    -webkit-transition: all $sec $transition;
    -moz-transition: all $sec $transition;
    -o-transition: all $sec $transition;
    transition: $sec $transition;
}

@mixin mixin-transition-delay($sec,$dur,$transition){
  -webkit-transition: all $dur $transition;
  -moz-transition: all $dur $transition;
  -o-transition: all $dur $transition;
  transition: all $dur $transition;
  -webkit-transition-delay: $sec;
  -moz-transition-delay: $sec;
  -o-transition-delay: $sec;
  transition-delay: $sec;
}

@mixin mixin-gradient-vertical($start,$end){
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzFlNTc5OSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM3ZGI5ZTgiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(top,  $start 0%, $end 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$start), color-stop(100%,$end)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  $start 0%,$end 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  $start 0%,$end 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  $start 0%,$end 100%); /* IE10+ */
	background: linear-gradient(to bottom,  $start 0%,$end 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start', endColorstr='$end',GradientType=0 ); /* IE6-8 */
}

@mixin mixin-box-shadow($horizontal,$vertical,$blur,$spread,$opacity){
	-webkit-box-shadow: $horizontal $vertical $blur $spread rgba(0,0,0,$opacity);
	-moz-box-shadow: $horizontal $vertical $blur $spread rgba(0,0,0,$opacity);
	box-shadow: $horizontal $vertical $blur $spread rgba(0,0,0,$opacity);
}

@mixin mixin-inset-shadow($horizontal,$vertical,$blur,$spread,$opacity){
	-webkit-box-shadow: inset $horizontal $vertical $blur $spread rgba(0,0,0,$opacity);
	-moz-box-shadow: inset $horizontal $vertical $blur $spread rgba(0,0,0,$opacity);
	box-shadow: inset $horizontal $vertical $blur $spread rgba(0,0,0,$opacity);
}


@mixin mixin-paralellogram($skew){
	transform: skew($skew);
	-webkit-transform: skew($skew);
   	-moz-transform: skew($skew);
    -o-transform: skew($skew);
}

@mixin mixin-paralellogram-content($skew){
	transform: translate(0, -50%) skew($skew);
	-webkit-transform: translate(0, -50%) skew($skew);
   	-moz-transform: translate(0, -50%) skew($skew);
    -o-transform: translate(0, -50%) skew($skew);
}

@mixin mixin-scale($scale){
	-webkit-transform: scale($scale);
	-moz-transform: scale($scale);
	-ms-transform: scale($scale);
	-o-transform: scale($scale);
	transform: scale($scale);
}

@mixin blue-gradient(){
	background: rgba(47,108,143,0.9);
    background: -moz-linear-gradient(top, rgba(47,108,143,0.9) 0%, rgba(16,71,108,0.9) 70%, rgba(16,71,108,0.9) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(47,108,143,0.9)), color-stop(70%, rgba(16,71,108,0.9)), color-stop(100%, rgba(16,71,108,0.9)));
    background: -webkit-linear-gradient(top, rgba(47,108,143,0.9) 0%, rgba(16,71,108,0.9) 70%, rgba(16,71,108,0.9) 100%);
    background: -o-linear-gradient(top, rgba(47,108,143,0.9) 0%, rgba(16,71,108,0.9) 70%, rgba(16,71,108,0.9) 100%);
    background: -ms-linear-gradient(top, rgba(47,108,143,0.9) 0%, rgba(16,71,108,0.9) 70%, rgba(16,71,108,0.9) 100%);
    background: linear-gradient(to bottom, rgba(47,108,143,0.9) 0%, rgba(16,71,108,0.9) 70%, rgba(16,71,108,0.9) 100%);
}

@mixin mixin-rotate($rotate) {
    -ms-transform: rotate($rotate);
    -webkit-transform: rotate($rotate);
    transform: rotate($rotate);
}

// Animation Delay
@mixin mixin-animation-delay($sec){
    -webkit-animation-delay: $sec;
    -moz-animation-delay: $sec;
    -o-animation-delay: $sec;
    -ms-animation-delay: $sec;
    animation-delay: $sec;
}

@mixin mixin-search-box($width, $margin_top) {
    width: 100%;
    max-width: $width;
    position: relative;
    margin: $margin_top 0 0;
    padding: 30px 25px 20px 40px;
    background: $white;
    @include mixin-box-shadow(0px, 0px, 8px, 1px, .10);
    label {
        font-size: 18px;
        display: block;
        text-transform: uppercase;
        color: $blue-dk;
        @extend %narrow-regular;
    }
    div {
        &.title {
            font-size: 25px;
            width: 14%;
            @extend %oswald-light;
        }
        &.location {
            width: 16%;
        }
        &.bedrooms,
        &.bathrooms {
            width: 13%;
        }
    }
    .line-diagonal{
        width: 1px;
        height: 100%;
        top: 0;
        background: $white-smoke;
        position: absolute;
        @include mixin-paralellogram(-25deg);
        &.line-1{
            left:15%;
        }
        &.line-2{
            right:8%;
        }
    }
    .action {
        margin-top: 27px;
        padding: 0 20px;
    }
    .dropdown-menu {
        min-width: 10px;
        padding: 0;
    }
    .dropdown {
        ul {
            &.location {
                li {
                    padding: 5px 10px;
                    border-bottom: 1px solid $gray-alto;
                }
            }
            &.bedrooms {
                li {
                    padding: 5px 10px;
                }
            }
            &.bathrooms {
                li {
                    padding: 5px 10px;
                }
            }
            li {
                &.price {
                    width: 100%;
                    padding-right: 10px;
                    padding-left: 10px;
                }
                
            }
        }
        .price-col1 {
            padding-right: 10px;
        }
        .price-col2 {
            padding-left: 10px;
        }
        .price-col1,
        .price-col2 {
            float: left;
            width: 50%;
            input[type='text'],
            input[type='number'] {
                width: 100%;
                margin-bottom: 20px;
            }
            ul > li {
                padding: 5px;
            }
        }
    }
}

@mixin link($color1, $color2: lighten($color1, 10%)){
    color: $color1;
    @include mixin-transition( .2s, ease-in);
    &:hover,
    &:active,
    &:focus {
        color: $color2;
        @include mixin-transition( .2s, ease-out);
    }
}

@mixin font-size-responsive($lg,$md,$sm,$xs){
    font-size: $lg;
    line-height: $lg * 1.25;
    @media #{$desktop} {
        font-size: $md;
        line-height: $md * 1.25;
    }
    @media #{$tablet}{
        font-size: $sm;
        line-height: $sm * 1.25;
    }
    @media #{$phone}{
        font-size: $xs;
        line-height: $xs * 1.25;
    }
}

/**
 * [font-em: outputs font-size in em units and line-height unitless]
 * @param  {[type]} $font-size   [description]
 * @param  {[type]} $line-height [description]
 * @return {[type]}              [description]
 * @example scss - Usage
 *    .foo {
 *        @include font-em(25, 45);
 *    }
 * @example css - Result
 *    .foo {
 *       font-size: 1.5625em;
 *       line-height: 1.8;   
 *    }
 */
@mixin font-em($font-size, $line-height: $font-size){
  font-size: em($font-size, $base-font);
  @if($line-height){
    line-height: ($line-height/$font-size);
  }
  @content;
}

/**
 * Page our Difference
 */

@mixin sections-our-difference($margin, $padding, $side, $width, $background-image, $background-size: contain){
  position: relative;
    margin: $margin;
    @include background-pseudo($side, $width, $size: contain){
    background-image: url('/images/site/our-difference/#{$background-image}');
    };
    .inner{
      padding: $padding;
    }
  @content;
}

/**
 * [header description]
 * @param  {[type]} $height           [description]
 * @param  {[type]} $background-image [description]
 */
@mixin page-header($background-image, $height: 650px, $font-size: 95px, $margin: 27% 0 0){
  position: fixed;
  top: 0;
  width: 100%;
  height: $height;
  color: #fff;
  text-shadow: 1px 1px 5px #353535;
  background-image: url('#{$background-image}');
  @include background-image;
  h1 {
      font-size: $font-size;
      margin: $margin;
      letter-spacing: 2.5px;
      color: #fff;
      @extend %oswald-light;
  }
  @content;
}

/**
 * [px to em]
 * @param  {[type]} $pixels   [description]
 * @param  {[type]} $context: $base-font [description]
 * @return {[type]}           [description]
 */
@function em($pixels, $context: $base-font) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1em;
}

/**
 * Tabs style mixin
 * @param  {[type]} $color1    [description]
 * @param  {[type]} $color2    [description]
 * @param  {[type]} $position: top           [description]
 * @return {[type]}            [description]
 */
@mixin tabs-param($color1, $color2, $position: top, $font-size: 18px) {
    
    .nav-tabs {
        display: inline-block;
        border-bottom: none;
        > li {
            display: inline;
            margin: 0 15px;
            @media #{$phone}{
              text-align: center; 
            }
            &:hover {
                > a {
                    color: $color1;
                    @include mixin-transition( .2s, ease-out);
                }
                > a:before {
                    width: 100%;
                    background: $color2;
                    @include mixin-transition( .2s, ease-out);
                }
            }
            > a {
                cursor: pointer;
                float: left;
                padding: 16px 0;
                @extend %oswald-regular;
                color: $color2;
                text-decoration: none;
                text-transform: uppercase;                
                border: 0;
                background-color: transparent;
                @include mixin-transition( .2s, ease-in);
                &:before {
                    display: block;
                    content: '';
                    position: absolute;
                    left: 0;
                    @if($position==top) {
                        top: 0;
                        bottom: auto;
                    }
                    else {
                        top: auto;
                        bottom: 0;
                    }
                    @include size(0, 3px);                    
                    @include mixin-transition( .2s, ease-in);
                }
                h2{
                  font-size: $font-size;
                  color: $color2;
                  margin: 0;
                  @media #{$phone}{
                    font-size: 13px;
                    letter-spacing: 2px;      
                  } 
                }
            }
            &.active {
                a {
                    color: $color1;
                    &:before {
                        width: 100%;
                        background: $color1;
                        @include mixin-transition( .2s, ease-out);
                    }
                    h2{
                      color: $color1;  
                    }
                }
            }
        }
        @content;
    }
}
