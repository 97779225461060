.homepage {
  @include fade(0, 1, 1.5s linear);
  ul {
    margin-bottom: 0;
  }
  .container-fluid {
    > .row {
      background-image: url('/images/site/homepage/tile.jpg'); 
      > div[class^="col-"] {
        &:nth-child(1) {
          padding-left: 0;
          padding-right: 0;
          @media #{$desktop-xl} {
            width: 16.66666667%;
          }
          .sidebar {
            &.upper {
              background-color: #E5E4DF;

              padding-top: 15px;
              padding-bottom: 15px;
              padding-left: 20px;
              padding-right: 20px;
    
              @media #{$desktop} {
                padding-left: 30px;
                padding-right: 30px;
              }
              @media #{$tablet} {
                padding-left: 45px;
                padding-right: 45px;
              }
              @media #{$phone} {
                padding-left: 45px;
                padding-right: 45px;
              }

              .logo-witherspoon {
                margin: 0 auto;
                @media #{$tablet} {
                  max-width: 300px;
                }
              }
              ul {
                margin-top: 30px;
                li {
                  color: #717171;
                  font-size: 13.5px;
                  line-height: 36px;

                  @media #{$tablet} {
                    display: inline-block;
                    width: 49.5%
                  }

                  &.available-home, &.available-retail-lot-for-sale, &.available-builder-owned-lot, &.sold-home {
                    polygon {
                      display: none;
                    }
                  }
                  &.available-home {
                    use:nth-child(2) {
                      fill: #5BB135;
                    }
                  }
                  &.available-retail-lot-for-sale {
                    use:nth-child(2) {
                      fill: #27A9CD;
                    }
                  }
                  &.available-builder-owned-lot {
                    use:nth-child(2) {
                      fill: #EAC80C;
                    }
                  }
                  &.community-amenity {
                    use:nth-child(2) {
                      fill: #2CD0AF;
                    }
                  }
                  &.sold-home {
                    use:nth-child(2) {
                      fill: #CD5A27;
                    }
                  }

                  svg {
                    margin-right: 15px;
                    vertical-align: middle;
                  }
                }
              }
            }
            &.lower {
              .btn-more-details {
                background-color: #A5A399;
                color: $white;
                font-size: 21px;
                padding: 12px 45px;
                text-align: left;
                width: 100%;
                .fa {
                  float: right;
                  margin-top: 3px;
                }
              }

              > div {
                > div {
                

                &.lower-1 {
                  background-color: #A5A399;
                  hr {
                    border-top-color: #929088;
                    margin-top: 0;
                    margin-bottom: 0;
                  }
                  > div {
                    padding-top: 15px;
                    padding-bottom: 15px;
                    padding-left: 20px;
                    padding-right: 20px;
          
                    @media #{$desktop} {
                      padding-left: 30px;
                      padding-right: 30px;
                    }
                    @media #{$tablet} {
                      padding-left: 45px;
                      padding-right: 45px;
                    }
                    @media #{$phone} {
                      padding-left: 45px;
                      padding-right: 45px;
                    }

                    .legend li {
                      color: #E5E4DF;
                      font-size: 16.5px;
                      line-height: 39px;

                      @media #{$tablet} {
                        display: inline-block;
                        width: 49.5%
                      }

                      .circle,
                      .square {
                        border: 1px solid #404040;
                        display: inline-block;
                        line-height: 31.5px;
                        margin-right: 15px;
                        width: 30px;
                        height: 30px;
                        text-align: center;
                        vertical-align: middle;
                      }
                      .circle {
                        border-radius: 30px;
                        &.section-number {
                          
                        }
                      }
                      .square {
                        &.custom-builder-homesite {
                          background-color: #A69A65;
                        }
                        &.ford-custom-classis-homes {
                          background-color: #D0A471;
                        }
                        &.turnberry-homes {
                          background-color: #F6EA9E;
                        }
                      }
                    }
                    p, .builders li {
                      color: #404040;
                      font-size: 16.5px;
                    }
                    .builders li {
                      line-height: 36px;

                      @media #{$tablet} {
                        display: inline-block;
                        width: 49.5%
                      }
                    }
                  }
                }
                &.lower-2 {
                  background-color: #929088;
                  padding-top: 15px;
                  padding-bottom: 15px;
                  padding-left: 20px;
                  padding-right: 20px;
        
                  @media #{$desktop} {
                    padding-left: 30px;
                    padding-right: 30px;
                  }
                  @media #{$tablet} {
                    padding-left: 45px;
                    padding-right: 45px;
                  }
                  @media #{$phone} {
                    padding-left: 45px;
                    padding-right: 45px;
                  }
                  li {
                    color: #E5E4DF;
                    font-size: 16.5px;
                  }    
                }
              }
                
              }
            }
          }
        }
        &.ph-lot-map {
          padding-left: 0;
          padding-right: 0;
          @media #{$desktop-xl} {
            width: 83.33333333%;
          }

          .lot {
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            position: absolute;
  
            @media #{$desktop}, #{$tablet} {
              margin-top: -3px;
            }
            @media #{$phone} {
              margin-top: -12px;
            }

            &.available-home, &.available-retail-lot-for-sale, &.available-builder-owned-lot, &.sold-home {
              polygon {
                display: none;
              }
            }
            &.available-home {
              use:nth-child(2) {
                fill: #5BB135;
              }
            }
            &.available-retail-lot-for-sale {
              use:nth-child(2) {
                fill: #27A9CD;
              }
            }
            &.available-builder-owned-lot {
              use:nth-child(2) {
                fill: #EAC80C;
              }
            }
            &.community-amenity {
              use:nth-child(2) {
                fill: #2CD0AF;
              }
            }
            &.sold-home {
              use:nth-child(2) {
                fill: #CD5A27;
              }
            }
            
            &:hover,
            &:focus,
            &:active {
              .flyout {
                @include specific-transition(opacity, 0.15s, ease-in);
                visibility: visible;
                opacity: 1;
              }
            }
  
            svg {
              @media #{$desktop}, #{$tablet} {
                width: 18px;
              }
              @media #{$phone} {
                width: 9px;
              }
            }
          }

          .flyout {
              background-color: $white;
              visibility: hidden;
              opacity: 0;
              padding: 15px;
              -webkit-box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
              box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
              position: absolute;
              z-index: 1;

              @media #{$desktop-hd}, #{$desktop} {
                width: 615px;
              }
              @media #{$tablet} {
                width: 525px;
              }
              @media #{$phone} {
                width: 270px;
              }

              &:before {
                content: '';
                position: absolute;
                transform: translate(-50%, 0);
                -webkit-transform: translate(-50%, 0);
                -ms-transform: translate(-50%, 0);
                width: 0; 
                height: 0; 
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                margin-left: 0px;
                z-index: 2;
              }
    
              &:after {
                position: absolute;
                width: 0;
                height: 0;
                content: '';
                z-index: -1;
                margin-left: 0px;
                -webkit-box-shadow: 8px 7px 10px -4px rgba(0, 0, 0, 0.3);
                box-shadow: 8px 7px 10px -4px rgba(0, 0, 0, 0.3);
                width: 37px;
                height: 37px;
              }

              &.top {
                top: 45px;
                &:before {
                  border-bottom: 20px solid $white;
                  top: -20px;
                }
                &:after {
                  top: -12px;
                  transform: rotate(-135deg);
                }
                &.left {
                  &:before {
                    @media #{$desktop-hd}, #{$desktop}, #{$tablet} {
                      left: 12%;
                    }
                    @media #{$phone} {
                      left: 16.5%;
                    }
                  }
                  &:after {
                    @media #{$desktop-hd}, #{$desktop}, #{$tablet} {
                      left: 9%;
                    }
                    @media #{$phone} {
                      left: 10.5%;
                    }
                  }
                }
                &.right {
                  &:before {
                    @media #{$desktop-hd}, #{$desktop}, #{$tablet} {
                      right: 6%;
                    }
                    @media #{$phone} {
                      right: 4%;
                    }
                  }
                  &:after {
                    @media #{$desktop-hd}, #{$desktop}, #{$tablet} {
                      right: 10%;
                    }
                    @media #{$phone} {
                      right: 11%;
                    }
                  }
                }
              }
              &.bottom {
                bottom: 45px;
                &:before {
                  border-top: 20px solid $white;
                  bottom: -20px;
                }
                &:after {
                  bottom: -12px;
                  transform: rotate(45deg);
                }
                &.left {
                  &:before {
                    @media #{$desktop-hd}, #{$desktop}, #{$tablet} {
                      left: 12%;
                    }
                    @media #{$phone} {
                      left: 22.5%;
                    }
                  }
                  &:after {
                    @media #{$desktop-hd}, #{$desktop}, #{$tablet} {
                      left: 9%;
                    }
                    @media #{$phone} {
                      left: 16%;
                    }
                  }
                }
                &.right {
                  &:before {
                    @media #{$desktop-hd}, #{$desktop}, #{$tablet} {
                      right: 5%;
                    }
                    @media #{$phone} {
                      right: 10%;
                    }
                  }
                  &:after {
                    @media #{$desktop-hd}, #{$desktop}, #{$tablet} {
                      right: 8.5%;
                    }
                    @media #{$phone} {
                      right: 17%;
                    }
                  }
                }
              }
              &.left {
                left: -60px;
              }
              &.right {
                right: -60px;
              }

              p, li {
                font-family: 'Open sans';
              }

              > .row  > div[class^="col-"] {
                &:nth-child(1) {

                  .carousel-inner > .item > img {
                    width: 100%;
                  }
                  .carousel-control {
                    background-color: transparent;
                    opacity: 1;
                    &.left {
                      text-align: left;
                    }
                    &.right {
                      text-align: right;
                    }
                    .fa {
                      background-color: #A5A399;
                      font-size: 30px;
                      margin-top: -15px;
                      padding-left: 3px;
                      padding-right: 3px;
                      position: absolute;
                      text-align: center;
                      top: 50%;
                      &.fa-angle-left {
                        left: 0;
                      }
                      &.fa-angle-right {
                        right: 0;
                      }
                    }
                  }
                }
                &:nth-child(2) {
                  p {
                    font-size: 16.5px;
                    strong {
                      font-family: 'Garamond';
                      font-size: 24px;
                    }
                  }
                  ul {
                    margin-top: 18px;
                    margin-bottom: 33px;
                    li {
                      color: #A5A399;
                      font-size: 13.5px;
                    }
                  }
                  .availability {
                    color: #897B2F;
                    font-size: 13.5px;
                    line-height: 23px;
                  }
                }
                &:nth-child(3) {
                  border-top: 1px solid #E5E4DF;
                  margin-top: 15px;
                  padding-top: 15px;
                  ul {
                    @media #{$desktop-hd}, #{$desktop}, #{$tablet} {
                      margin-left: 0;
                    }
                    li {
                      @media #{$desktop-hd}, #{$desktop}, #{$tablet} {
                        font-size: 16.5px;
                        &:first-child {
                          padding-left: 0;
                        }
                        &:last-child {
                          padding-right: 0;
                        }
                      }
                      @media #{$desktop-hd}, #{$desktop} {
                        padding-left: 15px;
                        padding-right: 15px;
                      }
                      @media #{$phone} {
                        font-size: 15px;
                        line-height: 30px;
                        width: 48%;
                      }
                      svg {
                        margin-right: 10px;
                        vertical-align: middle;
                      }
                    }
                  }
                }
                &:nth-child(4) {
                  border-top: 1px solid #E5E4DF;
                  margin-top: 15px;
                  padding-top: 15px;
                  p {
                    margin-bottom: 0;
                  }
                }
              }
            }
        }
        &.footer {
          background-color: #353F42;
          padding-top: 15px;
          padding-bottom: 15px;
          padding-left: 20px;
          padding-right: 20px;
  
          @media #{$desktop} {
            padding-left: 30px;
            padding-right: 30px;
          }
          @media #{$tablet} {
            padding-left: 45px;
            padding-right: 45px;
          }
          @media #{$phone} {
            padding-left: 15px;
            padding-right: 15px;
          }

          a {
            color: #869397;
            padding: 3px;
          }
          p, li {
            color: #869397;
            margin-bottom: 0;
          }
          li {
            font-size: 13.5px;
            line-height: 24px;
          }

          > .row > div[class^="col-"] {
            &:nth-child(1) {
              p {
                font-size: 13.5px;
                line-height: 24px;
              }
            }
            &:nth-child(3) {
              @media #{$phone} {
                padding-top: 30px;
              }
              li {
                vertical-align: top;
              }
            }
            &:nth-child(4) {
              p {
                font-size: 12px;
                line-height: 15px;
                margin-top: 15px;
              }
            }
            .logo-pinnacle {
              max-width: 100%;
            }
          }
        } 
      }
    }
  }
}