.scroll-viewport {
  overflow: hidden;
  position: relative;
}
.scroll-overview {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  margin: 0;
}
.scroll-bar {
  background-color: #d3d3d3;
  border-radius: 5px;
  position: absolute;
  top: 0;
  right: 0;
  width: 15px;
  transition: 0.6s ease-in-out opacity;
  height: 100%;
}
.scroll-bar.disable {
  display: none;
}
.scroll-bar.visible {
  opacity: 1 !important;
}
.scroll-bar:hover {
  opacity: 1 !important;
}
.scroll-thumb {
  background-color: #808080;
  border-radius: 5px;
  height: 20px;
  width: 13px;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 1px;
}
body.scroll-no-select {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
