@import "vendor/_bootstrap";
@import "vendor/_font-awesome";
@import "vendor/_hover";
@import "vendor/_ng-tiny-scrollbar";
@import "vendor/awesome-bootstrap-checkbox";
@import "vendor/animate";
@import "vendor/slick";
// @import "vendor/slick-theme";

@import "common/directives/directives";

@import "global/variables";
@import "global/fonts";
@import "global/mixins";
@import "global/animations";
/* @import "global/helpers";
@import "common/forms";
@import "common/panels";
@import "common/tables";
@import "common/buttons";
@import "common/carousel";
@import "common/modals"; */
@import "common/triggers";
@import "common/scrollbars";
/* @import 'common/nav-scrollspy';
@import 'common/cards'; */

/* @import "global/header";
@import "global/footer"; */

@import "homepage/index";
/* @import "site-map/site-map";

@import "error-404/error-404"; */

body {
  font-family: 'Garamond';
}
